import React from "react";
import "./Publication.scss";
import PublicationCard from "../../components/publicationCard/PublicationCard";
import {publicationInfo} from "../../portfolio";

export default function Publication() {
  if (publicationInfo.display) {
    return (
      <div className="education-section" id="publications">
        <h1 className="education-heading">Publications</h1>
        <div className="education-card-container">
          {publicationInfo.publications.map((publication, index) => (
            <PublicationCard key={index} publication={publication} />
          ))}
        </div>
      </div>
    );
  }
  return null;
}
