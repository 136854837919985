import React, {createRef, useContext} from "react";
import {Fade, Slide} from "react-reveal";
import "./PublicationCard.scss";
import StyleContext from "../../contexts/StyleContext";

export default function PublicationCard({publication}) {
  const imgRef = createRef();

  const GetDescBullets = ({descBullets}) => {
    return descBullets
      ? descBullets.map((item, i) => (
          <li key={i} className="subTitle">
            {item}
          </li>
        ))
      : null;
  };
  const {isDark} = useContext(StyleContext);

  if (!publication.teaser)
    console.error(`Image of ${publication.name} is missing in education section`);
  return (
    <div>
      <Fade left duration={1000}>
        <div className="education-card">
          {publication.teaser && (
            <div className="education-card-left">
              <a href={publication.videoURL} target="_blank" rel="noopener noreferrer">
                <img
                  crossOrigin={"anonymous"}
                  ref={imgRef}
                  className="publication-squareimg"
                  src={publication.teaser}
                  alt={publication.name}
                />
              </a>
            </div>
            )}
          <div className="education-card-right">
            <a className="no_href" href={publication.paperURL} target="_blank" rel="noopener noreferrer">
              <h5 className={
                isDark
                  ? "publication-text-white"
                  : "publication-text"
              }>{publication.name} </h5>
            </a>
              <div className="education-text-details">
                <h5
                  className={
                    isDark
                      ? "dark-mode education-text-subHeader"
                      : "education-text-subHeader"
                  }
                >
                  {publication.authors}
                </h5>
                <p
                  className={`${
                    isDark ? "dark-mode" : ""
                  } education-text-duration`}
                >
                  {publication.institutions}
                </p>
                <p className="education-text-desc">{publication.desc}</p>
                <div className="education-text-bullets">
                  <ul>
                    <GetDescBullets descBullets={publication.descBullets} />
                  </ul>
                </div>
              </div>
          </div>
        </div>
      </Fade>
      <Slide left duration={2000}>
        <div className="education-card-border"></div>
      </Slide>
    </div>
  );
}
