import React, {useContext} from "react";
import "./Research.scss";
import ResearchCard from "../../components/Research/ResearchCard";
import {illustration, researchSection} from "../../portfolio";
// import {greeting, illustration, researchSection} from "../../portfolio";
import {Fade} from "react-reveal";
import researchPerson from "../../assets/lottie/researchPerson";
import DisplayLottie from "../../components/displayLottie/DisplayLottie";
import StyleContext from "../../contexts/StyleContext";

export default function Research() {
  const {isDark} = useContext(StyleContext);
  if (!researchSection.display) {
    return null;
  }
  return (
    <div className={isDark ? "dark-mode main" : "main"} id="research">
      <div className="skills-main-div">
        <Fade left duration={1000}>
          <div className="skills-image-div">
            {illustration.animated ? (
              <DisplayLottie animationData={researchPerson} />
            ) : (
              <img
                alt="Man Working"
                src={require("../../assets/images/developerActivity.svg")}
              ></img>
            )}
          </div>
        </Fade>
        <Fade right duration={1000}>
          <div className="skills-text-div">
            <h1
              className={isDark ? "dark-mode skills-heading" : "skills-heading"}
            >
              {researchSection.title}{" "}
            </h1>
            <p
              className={
                isDark
                  ? "dark-mode greeting-text-p subTitle"
                  : "greeting-text-p subTitle"
              }
            >
              {researchSection.subTitle}
            </p>
            {/*<p*/}
            {/*  className={*/}
            {/*    isDark*/}
            {/*      ? "dark-mode subTitle skills-text-subtitle"*/}
            {/*      : "subTitle skills-text-subtitle"*/}
            {/*  }*/}
            {/*>*/}
            {/*  {researchSection.subTitle}*/}
            {/*</p>*/}
            <ResearchCard />
          </div>
        </Fade>
      </div>
    </div>
  );
}
